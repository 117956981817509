import { regex, required } from 'react-admin';
import { REGEX } from 'shared/constants/regex';
import { rolePermissions, Roles, Permissions } from '../../types';

export const isRequired = required('Обязательное поле');
export const isPasswordValid = regex(REGEX.fullPassword, 'Некорректный пароль');
export const isNameValid = regex(REGEX.name, 'Некорректное имя');

export const hasPermission = (role: Roles, permission: Permissions): boolean => {
  const permissionsForRole = rolePermissions[role];

  // Проверяем, есть ли такая роль в rolePermissions
  if (!permissionsForRole) {
    console.error(`Роль ${role} не найдена в маппере разрешений.`);
    return false; // Возвращаем false, если роль не найдена
  }

  return permissionsForRole.includes(permission);
}
