import dayjs from 'dayjs';
import React from 'react';
import ReactDOM from 'react-dom/client';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import App from './App';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
